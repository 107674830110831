import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import TooltipTable from "../../Components/Tables/TooltipTable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import HistoryIcon from "@mui/icons-material/History";
import "./PatientDemographics.scss";
import { useContext } from "react";
import { PatientSetContext } from "../../Contexts/patientSetContext";

var options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const combineCustomData = (data) => {
  if (!data) return [];

  const customData = Object.entries(data)
    // Step 1: Use filter to keep only the entries where the key starts with "cust_".
    .filter(([k]) => k.startsWith("cust_"))
    // Step 2: Use map to transform each entry into an object with a "key" and "value" property.
    .map(([k, v]) => ({
      key: k.slice(5).replace(/_/g, " "), // Step 3: Format the key (remove "cust_" and replace underscores with spaces).
      value: v
        ? v.charAt(0).toUpperCase() + v.slice(1).toLowerCase() // Step 4: Format the value (capitalize first letter, convert rest to lowercase).
        : "Unavailable", 
    }));

  return customData;
};


const PatientDemographics = ({ patientData, openQualitySelect }) => {
  const {patientSetInfo, analysisType} = useContext(PatientSetContext);
  const [open, setOpen] = useState(openQualitySelect);
  const { selected_date } = patientSetInfo;
  const [patientAttributes, setPatientAttributes] = useState([]);
  const { user } = JSON.parse(localStorage.getItem("user"));
  const { org_guid } = user;
  const carolinaOrgGuid = "10a7319f-b662-4c79-85fd-7fc5c9cc1461"

  useEffect(() => {
    let attributes = combineCustomData(patientData);
    if(analysisType === 'cost_analysis'){
      attributes = attributes.filter(
        (i) => i.key !== "gaps open" && i.key !== "gaps closed" && i.key !== "eligible measures"
      );
    }    
    setPatientAttributes(attributes);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisType])
  

  const decisionFunction = (content) => {
    if (content === "redacted") {
      return "Redacted";
    } else if (!content) {
      return "Unavailable";
    } else {
      return content;
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="patient-demographics">
        <div className="patient-demographics-container">
          <div className="patient-details-wrapper">
            <table className="p-table">
              <tbody>
                <tr>
                  <td className="p-td">
                    <span className="md-txt">Date Of Birth:</span>
                  </td>
                  <td className="p-td">
                    {patientData?.dob === "redacted" ? (
                      <Tooltip
                        className="tooltip-container-xl"
                        interactive
                        arrow
                        classes={{ tooltip: { maxWidth: 600 } }}
                        title={
                          <span className="mui-tooltip">
                            Personal Data has been redacted. Please contact your
                            administrator if you need access.
                          </span>
                        }
                      >
                        <span className="md-txt blurry-text">
                          {decisionFunction(patientData?.dob)}
                        </span>
                      </Tooltip>
                    ) : !patientData?.dob ? (
                      "Unavailable"
                    ) : (
                      patientData?.dob
                    )}
                  </td>
                </tr>

                <tr>
                  <td className="p-td">
                    <span className="md-txt">Comprehensive Risk:</span>
                  </td>
                  <td className="p-td">
                    {patientData?.comp_score ? (
                      <TooltipTable
                        score={patientData.comp_score}
                        title={"Comprehensive Risk"}
                        type={"comp_score"}
                        tableData={patientData.history}
                        service_date_end={patientData.service_date_end}
                      />
                    ) : (
                      `(Unavailable)`
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-td">
                    <span className="md-txt">Diagnostic Risk:</span>
                  </td>
                  <td className="p-td">
                    {patientData?.hcc_score ? (
                      <TooltipTable
                        score={patientData.hcc_score}
                        title={"Diagnostic Risk"}
                        type={"hcc_score"}
                        tableData={patientData?.history}
                        service_date_end={patientData.service_date_end}
                      />
                    ) : (
                      `(Unavailable)`
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-td">
                    <span className="md-txt">SDoH Risk:</span>
                  </td>
                  <td className="p-td">
                    {patientData?.sdoh_score ? (
                      <TooltipTable
                        score={patientData.sdoh_score}
                        title={"SDoH Risk"}
                        type={"sdoh_score"}
                        tableData={patientData.history}
                        calculation_time={patientData.calculation_time}
                      />
                    ) : (
                      `(Unavailable)`
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-td">
                    <span className="md-txt">Composite Quality score:</span>
                  </td>
                  <td className="p-td">
                    {patientData?.quality_score ? (
                      <TooltipTable
                        score={patientData.quality_score}
                        title={"Composite Quality score"}
                        type={"quality_score"}
                        tableData={patientData.history}
                        calculation_time={patientData.calculation_time}
                      />
                    ) : (
                      `(Unavailable)`
                    )}
                  </td>
                </tr>

                <tr>
                  <td className="p-td">
                    <span className="md-txt">Contributors:</span>
                  </td>
                  <td className="p-td">
                    <span className="md-txt patientInrvSelect">
                      {patientData?.contributors?.length ? (
                        <Select
                          variant="standard"
                          value="display"
                          width="170px"
                          MenuProps={{ disableScrollLock: true }}
                        >
                          <MenuItem
                            style={{ cursor: "none" }}
                            value="display"
                            disabled
                          >
                            <span>{patientData.contributors.length}&nbsp;</span>
                            <span>Contributor(s)</span>
                          </MenuItem>
                          {patientData?.contributors.map((item, index) => {
                            return (
                              <MenuItem
                                disabled
                                style={{ cursor: "none", opacity: 1 }}
                                key={index}
                              >{`${item.label} (${item.count})`}</MenuItem>
                            );
                          })}
                        </Select>
                      ) : (
                        "None"
                      )}
                    </span>
                  </td>
                </tr>

                {org_guid !== carolinaOrgGuid ? (
                  <tr>
                    <td className="p-td">
                      <span className="md-txt">Impactability:</span>
                    </td>
                    <td className="p-td">
                      {patientData?.impactability ? (
                        <TooltipTable
                          score={patientData.impactability}
                          title={"Impactability"}
                          type={"impactability"}
                          tableData={patientData.history}
                          service_date_end={patientData.service_date_end}
                        />
                      ) : (
                        `(Unavailable)`
                      )}
                    </td>
                  </tr>
                ) : null}

                <tr>
                  <td className="p-td">
                    <span className="md-txt">
                      Interventions (Services) Provided:
                    </span>
                  </td>
                  <td className="p-td">
                    <span className="md-txt patientInrvSelect">
                      {patientData?.services?.length ? (
                        <Select
                          variant="standard"
                          value="display"
                          width="200px"
                          MenuProps={{ disableScrollLock: true }}
                        >
                          <MenuItem
                            style={{ cursor: "pointer" }}
                            value="display"
                            disabled
                          >
                            <span>{patientData.services.length}&nbsp;</span>
                            <span>Intervention Types (in</span>
                            <span>
                              &nbsp;
                              {patientData.services.reduce(
                                (a, b) => a + b.count,
                                0
                              )}
                              &nbsp;
                            </span>
                            <span>Total Service Events)</span>
                          </MenuItem>
                          {patientData?.services.map((item, index) => {
                            return (
                              <MenuItem
                                style={{ cursor: "pointer" }}
                                key={index}
                              >{`${item.label} (${item.count})`}</MenuItem>
                            );
                          })}
                        </Select>
                      ) : (
                        "None"
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="p-td">
                    <span className="md-txt">
                      Quality (Services) Provided:
                    </span>
                  </td>
                  <td className="p-td">
                    <span className="md-txt patientInrvSelect">
                      {patientData?.patient_screening_details?.filter(item => item.measure_status === 1).length ? (
                        <Select
                          variant="standard"
                          value="display"
                          width="200px"
                          MenuProps={{ disableScrollLock: true }}
                          open={open}
                          onClose={handleClose}
                          onOpen={handleOpen}
                        >
                          <MenuItem
                            style={{ cursor: "pointer" }}
                            value="display"
                            disabled
                          >
                            <span>{patientData?.patient_screening_details?.filter(item => item.measure_status === 1).length}&nbsp;</span>
                            <span>Services Provided</span>
                          </MenuItem>
                          {patientData?.patient_screening_details?.map((item, index) => {
                            if (item?.measure_status) {
                                    return (
                                        <MenuItem key={index}>
                                            {`${item.screening_long_name}   (${item?.appointment_date})`}
                                        </MenuItem>
                                    );
                                  }
                                  return null;
                            })}
                        </Select>
                      ) : (
                        "None"
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex-divider"></div>
          <div className="patient-attributes-wrapper">
            <table className="p-table">
              <tbody>
                <tr>
                  <td className="p-td">
                    <span className="md-txt">Simulated Risks:</span>
                  </td>
                  <td className="p-td">
                    <span className="md-txt">Unavailable</span>
                  </td>
                </tr>
                <tr>
                  <td className="p-td">
                    <span className="md-txt">ER Visit :</span>
                  </td>
                  <td className="p-td">
                    <span className="md-txt">
                      {patientData?.er_visits || 0}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="p-td">
                    <span className="md-txt">IP Visit :</span>
                  </td>
                  <td className="p-td">
                    <span className="md-txt">
                      {patientData?.ip_visits || 0}
                    </span>
                  </td>
                </tr>
                {window.config.environment === "demo-a" && <tr>
                  <td className="p-td">
                    <span className="md-txt">Readmission	:</span>
                  </td>
                  <td className="p-td">
                    <span className="md-txt">{patientData?.readmission || 0}</span>
                  </td>
                </tr>}
                
                {patientAttributes.map(({ key, value }, index) => {
                  key =
                    key === "lob"
                      ? "LoB"
                      : key === "assigned pcp"
                      ? "Assigned PCP"
                      : key;
                  return (
                    <tr key={index}>
                      <td className="p-td">{key}</td>
                      <td className="p-td">{value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="note">
          <p className="lighten-txt">
            The information below is for service dates up to including{" "}
            {new Date(selected_date).toLocaleDateString("en", options)}.{" "}
            <HistoryIcon style={{ fontSize: "1rem" }}></HistoryIcon> icon to see
            data from all dates.
          </p>
        </div>
      </div>
    </>
  );
};

export default PatientDemographics;
