import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AuthContext } from "../../Contexts/authContext";
import { PatientsNumberContext } from "../../Contexts/totalPatientsContext";
import mergeLocalStorage from "../../Utils/mergeLocalStorage";
import { AnalyticsContext } from "../../Contexts/analyticsContext";
import { Badge, Button, Divider, Select, Tooltip, styled } from "@mui/material";
import "./DashboardHeader.scss";
import Avatar from "@mui/material/Avatar";
import DateSelect from "../DateSelect/DateSelect";
import dashlogo from "../../Assets/Images/dashboard-logo.png";
import withRouter from "../../Utils/withRouterHOC";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { toCapitalize, toPluralize } from "../../Utils/stringOprations";
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { red } from "@mui/material/colors";
import filterIcon from "../../Assets/Images/filterIcon-black.svg";
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import { makeStyles } from '@mui/styles';
import { sidebarContext } from "../../Contexts/sidebarContext";

function useOutsideClick(ref, onClose) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 20,
  height: 20,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const useStyles = makeStyles((theme) => ({
  closeIcon: {
     fill: '#fff !important',
     fontSize: '13px !important'
  },
}));

const Header = ({ homepage , editPage, pageTitle }) => {
  const classes = useStyles();
  const aka_session_enabled = localStorage.getItem("aka_session_enabled");
  const environment = window.config.environment;
  const history = useNavigate();
  const [show, setShow] = useState(false);
  const { logout, state, authLoading } = useContext(AuthContext);
  const { selectableDates: statsDates } = useContext(PatientsNumberContext);
  const { event } = useContext(AnalyticsContext);
  const { patientSets, patientSetInfo , updatePatientSetInfo, fetchingPatientSet, fetchPatientSets,
      analysisType, setAnalysisType } = useContext(PatientSetContext);
  const { state: filterState, dispatch } = useContext(DropdownFilterContext);
  const { initialized, setInitialized } = useContext(sidebarContext)
  
  const [filteredPatientSets, setFilteredPatientSets] = useState(patientSets);
  const [searchInput, setSearchInput] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [initials, setInitials] = useState("");
  const [username, setUsername] = useState("");
  const [adminUser, setAdminUser] = useState("");
  const [uuid, setUuid] = useState("");
  const [orgName, setOrgName] = useState("");
  const [patientSetName, setPatientSetName] = useState(null);
  const [filterName, setFilterName] = useState(null);
  const [time, setTime] = useState("");
  const [selectableDates, setSelectedDates] = useState([]);
  const { user } = JSON.parse(localStorage.getItem("user"));
  const location = useLocation(); // Get current location

  // Extracting the current page URL
  const currentPageUrl = location.pathname.substring(1); 

  const dropdownRef = useRef(null);

  const closeDropdown = () => setShow(false);

  useOutsideClick(dropdownRef, closeDropdown);

  useEffect(() => {
    const prevCohortList = JSON.parse(localStorage.getItem("cohort_list_tabs"));
    if (prevCohortList) {
      setFilteredPatientSets(
        prevCohortList.filter((patient) =>
            patient.name.toLowerCase().includes(searchInput.toLowerCase()) &&
            patientSetInfo?.patient_set_id !== patient.patient_set_id
        ));
    } else {
      setFilteredPatientSets(
        patientSets.filter((patient) =>
            patient.name.toLowerCase().includes(searchInput.toLowerCase()) &&
            patientSetInfo?.patient_set_id !== patient.patient_set_id
        ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, patientSets]);

  useEffect(()=>{
    if(currentPageUrl === "patient-list"){
      setAnalysisType("quality_analysis");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentPageUrl])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userDetails = (user) => {
    const first_name = user.first_name.toUpperCase();
    const last_name = user.last_name.toUpperCase();
    const username = toTitleCase(first_name) + " " + toTitleCase(last_name);
    const uuid = user.user_id;
    const orgName = user.org_name ? user.org_name : "None";
    const admin = JSON.parse(localStorage.getItem('backup_user'));
    setUuid(uuid);
    setUsername(username);
    if (admin) {
      setAdminUser(toTitleCase(admin.first_name) + " " + toTitleCase(admin.last_name))
    }
    setOrgName(orgName);
    return first_name[0] + last_name[0];
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleDateChange = (value) => {
    setTime(value);
    mergeLocalStorage("patient_set", {
      selected_date: value,
    });
    window.location.reload();
  };

  const exitAkaSubmit = () => {
    //Remove aka id and aka session enabled params from local storage
    localStorage.removeItem('aka_id');
    localStorage.removeItem('aka_session_enabled');
    localStorage.removeItem('aka_alert_open');
    localStorage.removeItem('aka_session_id');
    // Removing last patient set used in aka session
    localStorage.removeItem('patient_set');
    localStorage.removeItem('cohort_list_tabs');
    //Get the backup user (admin) and replace with the current aka user
    const backup_user = JSON.parse(localStorage.getItem('backup_user'));
    localStorage.setItem("user", JSON.stringify({
      isLoggedIn: true,
      user: backup_user
    }));
    localStorage.removeItem('backup_user');
    window.location.href = '/'
    setTimeout(() => {
      //Reload the page to load the changes in effect
      window.location.reload();
    }, 1000)
  };

  useEffect(() => {
    if (!initialized && !homepage && !editPage) {
      fetchPatientSets()
      setInitialized(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state && state.user) {
      const init = userDetails(state.user);
      setInitials(init);
    }
    
    if (!homepage && !editPage && patientSetInfo && !authLoading) {
      const name = formatName(patientSetInfo);
      setPatientSetName(name);
      filterState?.filterName 
        ? setFilterName(filterState.filterName)
        : setFilterName(null);
      // setPid(patientSetInfo.patient_set_id);
      setTime(patientSetInfo.selected_date);
      setSelectedDates(
        !patientSetInfo.selectable_dates ? statsDates : patientSetInfo.selectable_dates
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsDates, patientSetInfo, filterState, authLoading]);

  const formatName = (patientSet) => {
    if (!patientSet) return "";
    const { name, created, creator_name, creator_id } = patientSet;
    if (!name) {
      const date = new Date(created).toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      const string = `Created on ${date} by ${creator_name} (${creator_id})`;
      return string;
    } else {
      return name;
    }
  };

  const handleAnalysisTypeChange = (e) => {
    const { value } = e.target;
    setAnalysisType(value)
  }

  return (
    <div
      className="dashboard-header"
    >
      <div className="dashHdr_left">
        {!homepage && !editPage ? (
          <>
            <div className="patient-sets-dropdown" ref={dropdownRef}> 
              <div className="patient-sets-buttons">
              <Button
                onClick={() => setShow(!show)}
                variant="text"
                className="text-left h-auto d-flex align-items-start"
                size="small"
                sx={{ textTransform: 'none'}}
              >
                <div>
                  <p className="patient-set-name mb-0">{patientSetName}</p>
                  {/* <span className="patient-set-id">{pid}</span> */}
                </div>
                <div className="mx-2">
                  {show ? <ArrowDropUp /> : <ArrowDropDown />}
                </div>
              </Button>
                {filterName &&
                  <Button
                    variant="text"
                    className="text-left h-auto d-flex align-items-start"
                    size="small"
                    sx={{ textTransform: 'none'}}
                  >
                    <div className="d-flex flex-row">
                      <img src={filterIcon} alt="filterIcon" />
                      <p className="patient-set-name mb-0">{filterName}</p>
                    </div>
                    <Tooltip title="Remove Filter to show entire cohort" arrow>
                    <div className="mx-2">
                      <CancelIcon fontSize="small" onClick={() => dispatch({ type: 'reset-loaded-filters' })} />
                      </div>
                    </Tooltip>
                  </Button>
                }
              </div>
              {show && !fetchingPatientSet && (
                <ul>
                  <div className="searchBar">
                    <SearchIcon className="searchIcon" />
                    <input
                      className="searchInput"
                      placeholder="Search by cohort name"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  {filteredPatientSets.map((cohort, index) => {
                    if (cohort.selected) {
                      return (
                        <li
                          key={index}
                          className="patient-set-option"
                          onClick={(e) => {
                            updatePatientSetInfo(cohort);
                            window.location.reload();
                            setShow(false);
                          }}
                        >
                          <div className="patient-set-option">
                            <label className="patient-set-option">
                              {cohort?.name}
                            </label>
                          </div>
                        </li>
                      );
                    } else return null;
                  })}
                  <li>
                      <div className="patient-set-option" onClick={
                        (e) => {
                          history('/patient-sets');
                        }
                      }>
                      <label style={{ textDecoration: 'underline', color: '#0082B2', cursor: "pointer"}}>See All {toPluralize(toCapitalize(patientSetInfo.cohort_name))}</label>
                    </div>
                    </li>
                </ul>
              )}
            </div>
          </>
        ) : (
          <>
            <img src={dashlogo} className="dash-logo pl-3" alt="logo" />
          </>
        )}
      </div>
      <div className="dashHdr_center">
        {
          pageTitle !== "Comparison Analytics" && (
            <div className="page_title">{pageTitle}</div>
          )
        }
      </div>

      <div className="dashHdr_right">
        {!homepage && !editPage && (user?.org_guid === "10a7319f-b662-4c79-85fd-7fc5c9cc1461" || environment === "demo-a" ) && (
          <div className="analysis-type-dropdown">
            <Select
              variant={'outlined'}
              size={'small'}
              value={analysisType}
              onChange={handleAnalysisTypeChange}
              MenuProps={{ disableScrollLock: true }}
              disabled={currentPageUrl !== "contributors" && currentPageUrl !== "interventions"}
            >
              <MenuItem value={'cost_analysis'} key={'cost_analysis'}>Cost Analysis</MenuItem>
              <MenuItem value={'quality_analysis'} key={'quality_analysis'}>Quality Analysis</MenuItem>
            </Select>
          </div>
        )

        }
      {!homepage && !editPage && pageTitle !== "Comparison Analytics" && (
        <DateSelect
          time={time}
          selectableDates={selectableDates}
          handleDateChange={handleDateChange}
        />
      )}

        <div className="hdrRight_gridBx">
          <Link to="#" className="profileName">
            <div>
              {aka_session_enabled ? 
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <Tooltip title="Exit AKA" arrow>
                      <SmallAvatar sx={{ bgcolor: red[700] }} onClick={exitAkaSubmit}>
                        <CloseIcon className={classes.closeIcon} />
                      </SmallAvatar>
                    </Tooltip>
                  }
                >
                  <Avatar
                    onClick={(e) => handleClick(e)}
                    style={{
                      backgroundColor: "#0082b2",
                      padding: '24px'
                    }}
                  >
                    AKA
                  </Avatar>
                </Badge> : <Avatar
                  onClick={(e) => handleClick(e)}
                  style={{ backgroundColor: "#0082b2" }}
                >
                  {initials}
                </Avatar>}

              <Menu
                className="header__menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock={true}
              >
                {aka_session_enabled ?
                <>
                 <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="menu__uuid">
                    <span style={{ fontSize: "12px", fontWeight: "600", textTransform: 'uppercase' }}>
                      Logged-in User
                    </span>
                    <span>{adminUser}</span>
                  </div>
                </MenuItem>
                <Divider/>
                 <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="menu__uuid">
                    <span style={{ fontSize: "12px", fontWeight: "600", textTransform: 'uppercase' }}>
                      Viewing UI as User
                    </span>
                    <span>{username}</span>
                  </div>
                </MenuItem> </> :
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {username}
                  </MenuItem>}
                <Divider></Divider>
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="menu__uuid">
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      UUID
                    </span>
                    <span>{uuid}</span>
                  </div>
                </MenuItem>
                <Divider></Divider>
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="menu__uuid">
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      ORGANIZATION NAME
                    </span>
                    <span>{orgName}</span>
                  </div>
                </MenuItem>
                <Divider></Divider>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    logout();
                    event("logout_manual", {});
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Header);
