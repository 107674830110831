import React, { useContext, useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  ListItemText,
  MenuItem,
  MenuList,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";

const ColumnFilter = () => {
  const { state, dispatch } = useContext(DropdownFilterContext);
  const { columns } = state;
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClick = (event) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleChange = (key) => {
    //Check if key exists in columns and toggle its value
    const updatedColumns = columns.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          isSelected: !item.isSelected,
        };
      } else return item;
    });
    dispatch({ type: "set-patient-columns", payload: updatedColumns });
  };

  return (
    <div>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        size={"large"}
        variant="outlined"
      >
        Columns in View
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 9 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper sx={{ maxHeight: "60vh", overflowY: "scroll" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  dense
                >
                  {columns
                    .filter((item) => !["dob", "name"].includes(item.key))
                    .map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleChange(option.key)}
                      >
                        <Checkbox
                          checked={option.isSelected && option.isAvailable}
                          disabled={!option.isAvailable}
                        />
                        <ListItemText primary={option.title} />
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ColumnFilter;
