import React, { useContext, useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";

import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import NoDataMessage from "./NoDataMessage";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    width: "100%",
    maxHeight: "450px",
    marginBottom: "18px",
  },

  // chartContainer: {
  //   padding: "0 10px !important",
  // },
  chart: {
    "& .apexcharts-legend-marker": {
      display: "none",
    },
  },
  emptyData: {
    fontFamily: "Lato",
    fontSize: "14px !important",
    fontWeight: 600,
    textAlign: "center",
    padding: "20px 0px",
  },
  mangerInfo: {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px 10px 0px !important",
  },
  managerHeading:{
    marginLeft: "6px", 
    fontWeight: 600, 
    fontFamily: "Lato"
  }
}));

const DemoSkeleton =() =>(
    <>
      <Skeleton height={40} />
      <Skeleton height={40} />
      <Skeleton height={40} />
      <Skeleton height={40} />
      <Skeleton height={40} />
      <Skeleton height={40} />
    </>
  )

  const SkeletonLoader =() =>(
    <>
      <Skeleton height={100} />
      <Skeleton height={100} />
      <Skeleton height={100} />
    </>
  )

const SdohAnalysis = (props) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(WcchcPatientSummaryContext);

  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [empty, setEmpty] = useState(false);

  const defaultOptions = {
    chart: {
      height: "250px",
      type: "line",
      zoom: {
        enabled: false,
      },
      fontFamily: "Lato",
      toolbar: {
        show: false
      }
    },
    colors: [
      "#36E948",
      "#25CBFF",
      "#FFDC00",
      "#FF4136",
      "#0CB2B2",
      "#FF8514",
      "#5B5BCE",
    ],
    stroke: {
      curve: "straight",
      width: 2,
    },
    legend: {
      show: true,
      position: "left",
      width: "20px",
      fontSize: "12px",
      fontFamily: "Lato",
      color: "#6C7278",
      fontWeight: "400",
      inverseOrder: true,
      offsetY: -12,
      itemMargin: {
        vertical: 8.3,
      },
      markers: {
        width: 0,
        height: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      // row: {
      //   colors: ["#f3f3f3", "transparent"],
      //   opacity: 0.5,
      // },
    },
  };

  const getChartHeight = () =>{
    let length = chartData?.[0]?.['data'].length;
    if(length >= 30){
      return 370
    }

    if(length >= 24){
      return 365
    }

    return 350
  }

  useEffect(() => {
    if (state.sdohAnalysis) {
      let dates = state.sdohAnalysis.dates || [];
      let data = state.sdohAnalysis.series || [];
      dates = dates.slice(-props.duration);
      data = data.map((item) => {
        return {
          ...item,
          data: item.data.slice(-props.duration),
        };
      });

      const options = {
        ...defaultOptions,
        xaxis: {
          type: "category",
          categories: dates.map((item) => dayjs(item).format("MM/YY")),
          labels: {
            show: true,
            rotate: -90,
            rotateAlways: false,
            style: {
              cssClass: "apexcharts-xaxis-label",
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 7,
          min: 0,
          max: 70,
          labels: {
            show: false,
          },
        },
        markers: {
          size: 3,
          strokeWidth: 0,
          discrete: data
            .map((item, i) => {
              const { seriesIndex, firstPoint, lastPoint } = item;
              return [
                {
                  seriesIndex: seriesIndex,
                  dataPointIndex: firstPoint[props.duration],
                  size: 6,
                  fillColor: defaultOptions.colors[i],
                },
                {
                  seriesIndex: seriesIndex,
                  dataPointIndex: lastPoint[props.duration],
                  size: 6,
                  fillColor: defaultOptions.colors[i],
                },
              ];
            })
            .flat(),
        },
        tooltip: {
          shared: false,
          custom: function ({ s, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="arrow_box">' +
              '<span class="date"><span class="main">Started on : </span>' +
              data[seriesIndex].startDate +
              "</span>" +
              '<span class="date"><span class="main">End on : </span>' +
              data[seriesIndex].endDate +
              "</span>" +
              "</div>"
            );
          },
        },
      };

      let data2 = JSON.parse(JSON.stringify(data));
      setChartOptions(options);
      setChartData(data2);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.duration, JSON.stringify(state.sdohAnalysis)]);

  useEffect(() => {
    const empty = chartData.some((item) => item.data.some((i) => i));
    dispatch({ type: "UPDATE_TABLE_LENGTH", payload: {sdohAnalysis: empty ? 1 : 0} });
    setEmpty(!empty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  return (
    <Box className={`${classes.mainContainer} ${props.showBorder ? "selected-border" : ""}`} id="sdoh">
      <Box className={classes.chartContainer}>
        <Box className={classes.mangerInfo}>
          <Typography sx={{ fontFamily: "Lato" }}>Care Manager:</Typography>
          {state.isFetchingPatientDetails ? (
            <Skeleton width={100} height={30} sx={{ marginLeft: "6px" }} />
          ) : (
            <Typography className={classes.managerHeading}>
              {state.patientDetails.care_manager}
            </Typography>
          )}
        </Box>
        {state.isFetchingSdohAnalysis ? (
          props.demo ?
          <DemoSkeleton />
          :
          <SkeletonLoader />
        ) : !empty ? (
          <ReactApexChart
            options={chartOptions || {}}
            series={chartData || []}
            type="line"
            height={ getChartHeight()}
            className={classes.chart}
          />
        ) : (
            <NoDataMessage 
              message={`SDoH information is not available for last ${props.duration < 13 ? props.duration + ' months' : props.duration / 12 + ' years'}`}
            />
        )}
      </Box>
    </Box>
  );
};

export default SdohAnalysis;
