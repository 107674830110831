import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
import dayjs from "dayjs";

import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";
import NoDataMessage from "../../WcchcPatientSummary/NoDataMessage";

const useStyles = makeStyles((theme) => ({
  tableMainContainer: {
    width: "100%",
    overflow: "hidden",
    boxShadow: "none",
    fontFamily: "Lato",
    fontSize: "14px",
  },
  tableContainer: {
    boxShadow: "none",
    maxHeight: "270px",
  },
  tableContainerModal: {
    boxShadow: "none",
    maxHeight: "435px !important",
  },
  table: {
    paddingLeft: "20px !important",
    "& > tbody > tr:nth-child(even)": {
      backgroundColor: "#f3f3f3",
    },
    "& > thead > tr > th:nth-child(1)": {
      paddingLeft: "0px !important",
      textAlign: "left !important"
    },
    "& > tbody > tr > td:nth-child(1)": {
      paddingLeft: "0px !important",
      textAlign: "left",
    },
  },
  tableRow: {
    "& > td:first-child > div > p": {
      fontWeight: 600,
      verticalAlign: "text-top",
      padding: 0,
    },
  },
  tableHeaderCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#7D7B7B",
    padding: "8px 6px",
    textAlign: "center"
  },
  tableHeaderCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#060606",
    padding: "8px 6px",
    borderBottom: "0.5px solid #000000 !important",
  },
  tableDataCellContainer: {
    padding: "10px 10px",
    borderBottom: "none",
  },
  tableDataCellContainer2: {
    padding: "10px 10px",
  },
  tableDataCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "8px 6px",
    borderBottom: "none",
    verticalAlign: "middle !important",
  },
  tableDataCell2: {
    fontFamily: "Lato",
    fontSize: "14px !important",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#141414",
    borderBottom: "none",
    textAlign: "end",
  },
  tableDataCell2Alert: {
    fontFamily: "Lato",
    fontSize: "14px !important",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#FD0808",
    borderBottom: "none",
    textAlign: "end",
  },
  tableDataCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "8px 6px",
    verticalAlign: "middle !important",
  },
  tableDataCellDate: {
    fontFamily: "Lato",
    fontSize: "14px !important",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.03em",
    color: "#7D7B7B",
    borderBottom: "none",
    textAlign: "end",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
  },
  skeletonDataCell1: {
    width: "40%",
    borderBottom: "none",
    padding: "6px !important",
  },
  skeletonDataCell2: {
    borderBottom: "none",
    padding: "6px !important",
  },
  emptyTable: {
    fontFamily: "Lato",
    fontSize: "14px !important",
    fontWeight: 600,
    textAlign: "center",
    padding: "20px 0px",
  },
}));

const duplicatedCode = ["2160-0"];

const RecentLabsTable = ({ modal = false, maxHeight, duration, type }) => {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [columns, setColumns] = useState([]);
  const [maxLength, setMaxLength] = useState(0);

  const { state, dispatch } = useContext(WcchcPatientSummaryContext);

  useEffect(() => {
    const currentDate = dayjs().endOf("month");
    const pastDate = currentDate.subtract(duration, "month");

    const filteredData = {};
    let length = 0;

    Object.keys(state.labResults).forEach((key, index) => {
      const data = [];
      if (!duplicatedCode.includes(key)) {
        state.labResults[key].forEach((item) => {
          const date = dayjs(item.issued);
          if (date.isBetween(pastDate, currentDate)) {
            data.push(item);
          }
        });
      }      

      if (data.length) {
        filteredData[key] = data;
        data.length > length && (length = data.length);
      }
    });

    setData(filteredData);
    setColumns(Object.keys(filteredData));
    setMaxLength(length);
    if(type === 'modal'){
      dispatch({ type: "UPDATE_TABLE_LENGTH", payload: {recentlabsmodal: length} });
    }else{
      dispatch({ type: "UPDATE_TABLE_LENGTH", payload: {recentlabstable: length} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, state.labResults]);

  if (state.isFetchingLabResults) {
    return (
      <Paper className={classes.tableMainContainer}>
        <TableContainer sx={{ maxHeight }} className={modal ? classes.tableContainerModal : classes.tableContainer}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                {new Array(4).fill(0).map((_, i) => (
                  <TableCell key={i} className={classes.tableHeaderCell}>
                    <Skeleton variant="rounded" width="100%" height={30} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {new Array(7).fill(0).map((_, index) => (
                <TableRow key={index}>
                  {new Array(4).fill(0).map((_, i) => (
                    <TableCell
                      key={i}
                      className={
                        i
                          ? classes.skeletonDataCell2
                          : classes.skeletonDataCell1
                      }
                    >
                      <Skeleton variant="rounded" width="100%" height={30} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  return (
    <Paper className={classes.tableMainContainer}>
      <TableContainer sx={{ maxHeight }} className={modal ? classes.tableContainerModal : classes.tableContainer}>
        {
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
          >
            {maxLength ? <TableHead>
              <TableRow>
                {new Array(maxLength + 1).fill(0)?.map((column, index) => (
                  <TableCell
                    key={index}
                    className={
                      modal
                        ? classes.tableHeaderCellModal
                        : classes.tableHeaderCell
                    }
                  >
                    {index === 0 ? "Parameter" : "Value"}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            :
            ""
            }
            <TableBody>
              {!maxLength ? (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  className={classes.tableRow}
                >
                  <TableCell
                    className={
                      modal ? classes.tableDataCellModal : classes.tableDataCell
                    }
                  >
                    <NoDataMessage 
                        message={"No data available"}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                columns?.map((column, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      className={classes.tableRow}
                    >
                      <TableCell
                        className={
                          modal
                            ? classes.tableDataCellModal
                            : classes.tableDataCell
                        }
                      >
                        <Box className={classes.boxContainer}>
                          <Typography
                            className={
                              modal
                                ? classes.tableDataCellModal
                                : classes.tableDataCell
                            }
                          >
                            {state.labResults[column][0]?.code}{" "}
                          </Typography>
                          <Typography
                            className={
                              modal
                                ? classes.tableDataCellModal
                                : classes.tableDataCell
                            }
                          >
                            ( {state.labResults[column][0]?.valueQuantity.unit}{" "}
                            )
                          </Typography>
                        </Box>
                      </TableCell>
                      {data[column].length &&
                        data[column].map((data, index) => {
                          return (
                            <TableCell
                              key={index}
                              className={
                                modal
                                  ? classes.tableDataCellContainer2
                                  : classes.tableDataCellContainer
                              }
                            >
                              <Box className={classes.boxContainer}>
                                <Typography
                                  className={
                                    data.attentive
                                      ? classes.tableDataCell2Alert
                                      : classes.tableDataCell2
                                  }
                                >
                                  {data.valueQuantity.value}{" "}
                                </Typography>
                                <Typography
                                  className={classes.tableDataCellDate}
                                >
                                  {dayjs(data.issued).format("MM/DD/YYYY")}
                                </Typography>
                              </Box>
                            </TableCell>
                          );
                        })}
                      {maxLength - data[column].length
                        ? new Array(maxLength - data[column].length)
                            .fill(0)
                            .map((_, k) => (
                              <TableCell
                                key={k}
                                className={
                                  modal
                                    ? classes.tableDataCellContainer2
                                    : classes.tableDataCellContainer
                                }
                              ></TableCell>
                            ))
                        : null}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        }
      </TableContainer>
    </Paper>
  );
};

export default RecentLabsTable;
