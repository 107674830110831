export const DISABLE_PAGE_FOR_USER = [
  "2468b428-f031-70f7-e7c2-31933a040c8b"
]

export const health_trends_radio_list = [
  { label: "Vitals", value: "vitals" },
  { label: "Diabetes", value: "diabetes" },
  { label: "Cardiovascular", value: "cardiovascular" },
  { label: "Pulmonary", value: "pulmonary" },
  { label: "Renal", value: "renal" },
  { label: "Obesity", value: "morbidObesity" },
];


export const healthModalDropDownList = (longList = false) => {
  const options = [
    {
      label: "Last 3 Months",
      value: 3,
    },
    {
      label: "Last 6 Months",
      value: 6,
    },
    {
      label: "Last 9 Months",
      value: 9,
    },
    {
      label: "Last 12 Months",
      value: 12,
    },
  ];

  if (longList) {
    return [
      ...options,
      {
        label: "Last 2 Years",
        value: 24,
      },
      {
        label: "Last 3 Years",
        value: 36,
      },
    ];
  }

  return options;
};

export const notification_type = {
  MISSING_CODE_IDENTIFIED: "Missing Code Identified",
  IP_ER_VISIT: "Hospital Admits/ER Visits",
  IP_VISIT: "Hospital Admits",
  ER_VISIT: "ER Visits",
  LAB_RESULTS: "Lab Results",
  SDOH_IDENTIFIED: "SDoH Identified",
  RECOMMENDED_SERVICE: "Recommended Services",
  READMISSION: "30-Day Readmission",
  HIGH: "High"
}

export const notification_type_tab_value = {
  MISSING_CODE_IDENTIFIED: 1,
  IP_ER_VISIT: 3,
  IP_VISIT: 3,
  ER_VISIT: 3,
  LAB_RESULTS: 4,
  SDOH_IDENTIFIED: 0,
  RECOMMENDED_SERVICE: 2,
  READMISSION: 3
}


export const notifications_data = [
  {
    id: 1,
    date: "06/16/2024 04:00PM",
    text: "An *ICD-10 Code* was identified that was missing from claims in 07/12/2024.",
    type: "MISSING_CODE_IDENTIFIED",
    viewed: false,    
    priority: "HIGH",
  },
  {
    id: 2,
    date: "06/07/2024 09:32AM",
    text: "Patient had a recent *Inpatient admission on 06/12/2024*.",
    type: "IP_ER_VISIT",
    viewed: true,    
    priority: "HIGH",
  },
  {
    id: 3,
    date: "05/30/2024 06:00AM",
    text: "Patient had a Lab result from the *HbA1C in 07/11/2024* that had a abnormal result.",
    type: "LAB_RESULTS",
    viewed: true,    
    priority: "HIGH",
  },
  {
    id: 4,
    date: "05/30/2024 06:00AM",
    text: "Patient has a new SDoH Risk Contributor *Homelessness in 07/05/2024*.",
    type: "SDOH_IDENTIFIED",
    viewed: true,    
    priority: "",
  },
  {
    id: 5,
    date: "05/30/2024 06:00AM",
    text: "Patient is in need of Home Shelter from 07/06/2024.",
    type: "RECOMMENDED_SERVICE",
    viewed: true,    
    priority: "",
  },
  {
    id: 6,
    date: "06/16/2024 04:00PM",
    text: "An *ICD-10 Code 104567* was identified that was missing from claims in 07/19/2024. Click to view Smart Alerts",
    type: "MISSING_CODE_IDENTIFIED",
    viewed: true,    
    priority: "HIGH",
  },
  {
    id: 7,
    date: "06/07/2024 09:32AM",
    text: "Patient had a recent *ER visit on 07/12/2024*.",
    type: "IP_ER_VISIT",
    viewed: true,    
    priority: "HIGH",
  },
];


export const ORG_ID = {
  "demo": "50cc73a6-23c1-49ba-8276-eaf6589c789f",
  "demo-a": "50cc73a6-23c1-49ba-8276-eaf6589c789f",
  "uat": "2c6d7563-a160-11eb-848f-02a70ebebca3",
  "production": "2c6d7563-a160-11eb-848f-02a70ebebca3",
  "dev": "50cc73a6-23c1-49ba-8276-eaf6589c789f",
};

export const PATIENTS_DATA_UAT = [
  {
    id : 1,
    patientId : "09DF0BEC-1106-4CC1-A66D-A1AC74F6041D",
    summary : "Patient ID 09DF0BEC presents multiple medical concerns: pathologic fracture in other disease, secondary thrombocytopenia in diseases classified elsewhere, psoriasis, chronic kidney disease (stage 3a), alcohol-induced chronic pancreatitis, hypertension, and unspecified asthma. The diverse medication regimen, including Protonix, lisinopril, metoprolol succinate, and more, addresses these conditions for symptom management and relief. The recent vital measurements on 03-07-2023 at 8:07 PM revealed a blood pressure of 142/100 mmHg, a height of 121.25 cm, a weight of 55 kg, a BMI of 16, and a pulse rate of 95. Monitoring and strict adherence to the prescribed treatment plan are crucial for the patient's overall health."
  },
  {
    id : 2,
    patientId : "5D876E19-A93B-4972-9D32-D2A2DBDFEC0F",
    summary : "The patient presents a complex medical profile characterized by conditions such as overeating, exogenous obesity, mental disorders subsequent to tobacco use, and a spectrum of psychological concerns, including unspecified dementia, schizophrenia, and depression with suicidal ideation. Concurrently, the patient manages chronic health issues like COPD, alcoholic liver disease, and respiratory insufficiency. Their medication regimen comprises a diverse array addressing pain relief, infections, and various cardiovascular and respiratory health aspects. Vital signs recorded on 12-8-2023 reflect slightly elevated blood pressure alongside normal weight, pulse, and respiration rates, necessitating continuous monitoring and a comprehensive cardiovascular health strategy. The patient's intricate medical history underscores the importance of tailored care and consistent treatment adherence."
  },
  {
    id : 3,
    patientId : "FD4E5DBE-5766-4383-A6EE-5FA80C0C2A36",
    summary : "The patient's medical profile indicates a diagnosis of schizoaffective disorder, along with a case of mild intermittent asthma showing acute exacerbation. Current medications include Latuda 40 mg, metformin 500 mg, Prenatal 28 mg-800 mcg, and Provera 10 mg. On 12-6-2023 at 10:53 AM, vital signs displayed a blood pressure of 117/80 mmHg (systolic/diastolic), a weight of 346.13 lbs (157 kg), with a slightly elevated pulse rate of 87 bpm and a normal respiratory rate of 16 breaths per minute. Oxygen saturation stood at 100%. Attention to the asthma exacerbation, schizoaffective disorder management, and medication adherence is recommended, considering the patient's vital signs."
  },
  {
    id : 4,
    patientId : "E39AE702-DFAC-4887-B537-00CFDD28BFB4",
    summary : "The patient exhibits a complex mental health profile, diagnosed with pervasive developmental disorder, depression, bipolar disorder, and unspecified intellectual disabilities. The medication list includes aripiprazole, Depakote, duloxetine, folic acid, guanfacine, haloperidol, hydroxyzine pamoate, quetiapine, prazosin, Abilify Maintena, acetaminophen, ibuprofen, omeprazole, hydrocortisone, cephalexin, Benadryl, ProAir HFA, Alevazol, bacitracin, Depo-Provera, and melatonin. Recorded vitals on June 12, 2023, at 7:36 PM, reveal elevated blood pressure (145/104), an increased pulse rate of 118, a respiration rate of 24, and a high oxygen saturation level of 98%."
  },
  {
    id : 5,
    patientId : "D0DBA68A-522C-4836-BC1B-EB3207B3E60A",
    summary : "The patient data reveals a complex health profile, encompassing chronic heart failure, hypertension, severe obesity with hypoventilation, and coexisting kidney disease. The prescribed medications include omeprazole, ondansetron, topiramate, potassium chloride, Truvada, Ozempic, acetaminophen, gabapentin, Narcan, Xarelto, albuterol, Senna Laxative, morphine, pregabalin, methocarbamol, furosemide, trazodone, and Vitamin D2. Recorded vitals on January 12, 2023, at 10:29 AM, indicate elevated blood pressure (163/95), a significant weight of 539.4 lbs, and a high BMI of 75.22. Other parameters include a pulse rate of 89, regular pulse pattern, respiration rate of 22, and 97% oxygen saturation."
  },
  {
    id : 6,
    patientId : "844D18D5-9C3B-4688-85FF-1EAFE6C1ECF6",
    summary : "The patient presents with uncontrolled diabetes mellitus, displaying associated neurological complications and an enlarged prostate without urinary symptoms. Their medication regimen comprises a variety of medications, including Bactrim DS, Pyridium, acetaminophen, Regranex, Crestor, trazodone, Cipro, bumetanide, and others, as well as medical equipment such as the OneTouch Ultra2 Meter and Toujeo Max SoloStar. Vital signs observed include a blood pressure of 120/78 mm Hg, a height of 177.8 cm, a weight of 220 lbs (99.8 kg), and a calculated BMI of 31.56. The patient's pulse rate is 94 bpm, respiration rate is 20, and SpO2 level stands at 99%."
  }
];

export const PATIENTS_DATA_DEV = [
  {
    id: 1,
    patientId: "IF2020-123-1",
    summary: "In the medical note, the patient had upcoming appointments but couldn't be reached by phone. They plan to monitor the patient as per UHC criteria and aim for a second contact within a month. The patient seeks food resources and relies on Handi Van for groceries. They suffer from uncontrolled diabetes with neurologic complications and an enlarged prostate. Medications include Bactrim DS, Pyridium, acetaminophen, Regranex, Crestor, trazodone, Cipro, bumetanide, Trulicity, metoprolol succinate ER, Eliquis, Farxiga, and Senna-Time S. Vital signs show a blood pressure of 120/78 mm Hg, height 177.8 cm, weight 220 lbs, BMI 31.56, pulse rate 94 bpm, respiration rate 20, and SpO2 at 99%."
  },
];

export const PATIENTS_DATA_DEMO = [
  {
    id : 1,
    patientId : "IF2020-123-1",
    summary : "Patient presents with multiple medical concerns: pathologic fracture in other disease, secondary thrombocytopenia in diseases classified elsewhere, psoriasis, chronic kidney disease (stage 3a), alcohol-induced chronic pancreatitis, hypertension, and unspecified asthma. The diverse medication regimen, including Protonix, lisinopril, metoprolol succinate, and more, addresses these conditions for symptom management and relief. The recent vital measurements on 03-07-2023 at 8:07 PM revealed a blood pressure of 142/100 mmHg, a height of 121.25 cm, a weight of 55 kg, a BMI of 16, and a pulse rate of 95. Monitoring and strict adherence to the prescribed treatment plan are crucial for the patient's overall health."
  },
];

export const loinc_codes = {
                diabetes: ["4548-4", "2089-1", ["38483-4", "2160-0"], "50210-4", "1743-4", "14957-5"],
                cardiovascular: ["2089-1","2571-8","38483-4","50210-4"],
                pulmonary: ["718-7", "20570-8", "2703-7"],
                renal: ["50210-4","38483-4","14957-5","718-7","20570-8"],
                morbidObesity: ["2085-9", "3016-3", "4548-4", "1743-4", "2089-1", "14957-5",],
              };

export const LOINC_CODES ={
                  diabetes: [
                    {name: "Hemoglobin A1C", codes:["4548-4"]},
                    {name: "LDL Cholesterol Direct", codes:["2089-1"]},
                    {name: "Creatinine", codes:["38483-4", "2160-0"]},
                    {name: "GFR", codes:["50210-4"]},
                    {name: "ALT", codes:["1743-4"]},
                    {name: "Microalbumin Urine", codes:["14957-5"]}
                  ],
                  cardiovascular: [
                    {name: "LDL Cholesterol Direct", codes:["2089-1"]},
                    {name: "Triglycerides", codes:["2571-8"]},
                    {name: "Creatinine", codes:["38483-4", "2160-0"]},
                    {name: "GFR", codes:["50210-4"]},
                  ],
                  pulmonary: [
                    {name: "SPO2", codes:["2703-7"]},
                  ],
                  renal: [
                    {name: "GFR", codes:["50210-4"]},
                    {name: "Creatinine", codes:["38483-4", "2160-0"]}, 
                    {name: "Urine Albumin", codes:["14957-5"]}, 
                    {name: "Hemoglobin", codes:["718-7"]}, 
                    {name: "Hematocrit", codes:["20570-8"]},
                  ],
                  morbidObesity: [
                    {name: "BMI", codes:["2085-9"]},
                    {name: "TSH", codes:["3016-3"]},
                    {name: "Hemoglobin A1C", codes:["4548-4"]},
                    {name: "ALT", codes:["1743-4"]},
                    {name: "LDL Cholesterol Direct", codes:["2089-1"]},
                    {name: "Urine Albumin", codes:["14957-5"]}, 
                  ],
}

export const colors = [
                "#36E948",
                "#25CBFF",
                "#0CB2B2",
                "#5B5BCE",
                "#FFDC00",
                "#36E948",
                "#25CBFF",
                "#0CB2B2",
                "#5B5BCE",
                "#FFDC00",
                "#36E948",
                "#25CBFF",
                "#0CB2B2",
                "#5B5BCE",
                "#FFDC00",
            ];

export const SDOH_DEMO_DATA = {
  "dates": [
      "2021-01-01T00:00:00.000Z",
      "2021-02-01T00:00:00.000Z",
      "2021-03-01T00:00:00.000Z",
      "2021-04-01T00:00:00.000Z",
      "2021-05-01T00:00:00.000Z",
      "2021-06-01T00:00:00.000Z",
      "2021-07-01T00:00:00.000Z",
      "2021-08-01T00:00:00.000Z",
      "2021-09-01T00:00:00.000Z",
      "2021-10-01T00:00:00.000Z",
      "2021-11-01T00:00:00.000Z",
      "2021-12-01T00:00:00.000Z",
      "2022-01-01T00:00:00.000Z",
      "2022-02-01T00:00:00.000Z",
      "2022-03-01T00:00:00.000Z",
      "2022-04-01T00:00:00.000Z",
      "2022-05-01T00:00:00.000Z",
      "2022-06-01T00:00:00.000Z",
      "2022-07-01T00:00:00.000Z",
      "2022-08-01T00:00:00.000Z",
      "2022-09-01T00:00:00.000Z",
      "2022-10-01T00:00:00.000Z",
      "2022-11-01T00:00:00.000Z",
      "2022-12-01T00:00:00.000Z",
      "2023-01-01T00:00:00.000Z",
      "2023-02-01T00:00:00.000Z",
      "2023-03-01T00:00:00.000Z",
      "2023-04-01T00:00:00.000Z",
      "2023-05-01T00:00:00.000Z",
      "2023-06-01T00:00:00.000Z",
      "2023-07-01T00:00:00.000Z",
      "2023-08-01T00:00:00.000Z",
      "2023-09-01T00:00:00.000Z",
      "2023-10-01T00:00:00.000Z",
      "2023-11-01T00:00:00.000Z",
      "2023-12-01T00:00:00.000Z"
  ],
  "series": [
      {
          "name": "Unemployment",
          "type": "line",
          "data": [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              10,
              10,
              10,
              10,
              10,
              10,
              10,
              10,
              10,
              10,
              10,
              10,
              10,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
          ],
          "seriesIndex": 0,
          "startDate": "02/22",
          "firstPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": 1,
              "24": 1,
              "36": 13
          },
          "lastPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": -1,
              "24": 13,
              "36": 25
          },
          "endDate": "02/23"
      },
      {
          "name": "Transport Issue",
          "type": "line",
          "data": [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              20,
              20,
              20,
              20,
              20,
              20,
              20,
              20,
              20,
              20,
              20,
              20,
              20,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
          ],
          "seriesIndex": 1,
          "startDate": "02/22",
          "firstPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": 1,
              "24": 1,
              "36": 13
          },
          "lastPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": -1,
              "24": 13,
              "36": 25
          },
          "endDate": "02/23"
      },
      {
          "name": "Income Issue",
          "type": "line",
          "data": [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              30,
              30,
              30,
              30,
              30,
              30,
              30,
              30,
              30,
              30,
              30,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
          ],
          "seriesIndex": 2,
          "startDate": "04/22",
          "firstPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": 1,
              "24": 3,
              "36": 15
          },
          "lastPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": -1,
              "24": 13,
              "36": 25
          },
          "endDate": "02/23"
      },
      {
          "name": "Living Alone",
          "type": "line",
          "data": [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              40,
              40,
              40,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
          ],
          "seriesIndex": 3,
          "startDate": "02/22",
          "firstPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": -1,
              "24": 1,
              "36": 13
          },
          "lastPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": -1,
              "24": 3,
              "36": 15
          },
          "endDate": "04/22"
      },
      {
          "name": "Homelessness",
          "type": "line",
          "data": [
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              50,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
          ],
          "seriesIndex": 4,
          "startDate": "01/21",
          "firstPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": 1,
              "24": 13,
              "36": 0
          },
          "lastPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": -1,
              "24": -1,
              "36": 25
          },
          "endDate": "02/23"
      },
      {
          "name": "Mental Health Access Issue",
          "type": "line",
          "data": [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
          ],
          "seriesIndex": 5,
          "startDate": "12/23",
          "firstPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": -1,
              "24": -1,
              "36": -1
          },
          "lastPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": -1,
              "24": -1,
              "36": -1
          },
          "endDate": "-"
      },
      {
          "name": "Food Insecurity",
          "type": "line",
          "data": [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              70,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
          ],
          "seriesIndex": 6,
          "startDate": "02/23",
          "firstPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": 1,
              "24": 13,
              "36": 25
          },
          "lastPoint": {
              "3": -1,
              "6": -1,
              "9": -1,
              "12": -1,
              "24": -1,
              "36": -1
          },
          "endDate": "02/23"
      }
  ]
};

export const EHR_VISITS_DEMO_DATA = [
  {
    "visit_type": "IP",
    "visit_date": "04/02/2024",
    "facility_name": "Cityside Care Hospital"
  },
  {
    "visit_type": "ER",
    "visit_date": "03/15/2024",
    "facility_name": "Cityside Care Hospital"
  },
  {
    "visit_type": "ER",
    "visit_date": "02/10/2024",
    "facility_name": "Cityside Care Hospital"
  },
  {
    "visit_type": "IP",
    "visit_date": "01/05/2024",
    "facility_name": "Cityside Care Hospital"
  },
  {
    "visit_type": "ER",
    "visit_date": "12/20/2023",
    "facility_name": "Cityside Care Hospital"
  },
  {
    "visit_type": "IP",
    "visit_date": "11/11/2023",
    "facility_name": "Cityside Care Hospital"
  },
  {
    "visit_type": "IP",
    "visit_date": "10/08/2023",
  },
  {
    "visit_type": "ER",
    "visit_date": "07/17/2023",
    "facility_name": "Cityside Care Hospital"
  },
  {
    "visit_type": "ER",
    "visit_date": "06/16/2023",
  },
];

export const DEMO_SUMMARY = `Medical History:
Significant Diagnoses:
Chronic Kidney Disease Stage 5 (most recent: 01/30/2021)
Diabetes with Chronic Complications (most recent: 03/20/2020)
Atherosclerosis of the Extremities with Ulceration (most recent: 09/05/2020)
Chronic Hepatitis B (most recent: 11/01/2021)
Major Depressive Disorder (most recent: 02/12/2021)
Recent Hospitalizations: Inpatient admission on 04/02/2024 at Cityside Care Hospital 
Recent Emergency Room Visits: Three visits in the last 3 months (most recent on 03/15/2024 at Cityside Care Hospital).
Active Medications:
Sertraline 50mg daily (antidepressant)
Lisinopril 10mg daily (blood pressure medication)
Amlodipine 5mg daily (blood pressure medication)
Erythropoietin injection (frequency not specified) (for anemia)
Metformin 500mg twice daily (diabetes medication)
Insulin Glargine 10 units daily (diabetes medication)
Hepatitis B vaccine (ongoing)
Lab Results:
Creatinine: Slightly elevated in recent labs (1.7 - 1.8 mg/dL).
Triglycerides: Elevated in recent labs (150 - 170 mg/dL).
LDL Cholesterol: Elevated in March 2024 lab (120 mg/dL).
Hemoglobin A1c: Well-controlled diabetes (7.8 - 8.2%).
Social History:
Unemployed: Recently lost job and struggling financially.
Housing Issues: Currently homeless, couch surfing with friends.
Transportation Issues: Relies on others for rides to appointments and has missed appointments in the past.
Referrals:
Cardiology consult for evaluation of possible heart arrhythmias (pending).
Neurology consult for routine check (pending).
Psychiatry consult for ongoing depression (pending).
Clinician Action Items:
Review recent labs and address elevated creatinine and triglycerides.
Discuss social determinants of health, including unemployment, homelessness, and transportation issues.
Follow up on outstanding referrals to cardiology, neurology, and psychiatry
`
export const MISSING_CODES = [
  {
    "id": 2,  // Assuming a unique ID for this record
    "date": "01/30/2024",
    "hcc_code": "85",
    "hcc_description": "Heart Failure",
    "source": "Past Claims",
    "icd": [
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df048",
        "category": "ICD-10",
        "code": "I50.10",
        "description": "Congestive heart failure, unspecified",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df049",
        "category": "ICD-10",
        "code": "I50.21",
        "description": "Diastolic heart failure",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df050",
        "category": "ICD-10",
        "code": "I50.90",
        "description": "Heart failure, unspecified",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df051",
        "category": "ICD-10",
        "code": "I11.0",
        "description": "Hypertensive heart disease with heart failure",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df052",
        "category": "ICD-10",
        "code": "I25.5",
        "description": "Ischemic cardiomyopathy with heart failure",
        "applicable": false,
        "addressed_date": "-"
      }
    ]
  },  
  {
    "id": 3,  // Assuming a unique ID for this record
    "date": "02/15/2024",
    "hcc_code": "108",
    "hcc_description": "Peripheral Vascular Disease",
    "source": "Past Claims",
    "icd": [
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df053",
        "category": "ICD-10",
        "code": "I70.2",
        "description": "Atherosclerosis of abdominal aorta and iliac arteries",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df054",
        "category": "ICD-10",
        "code": "I73.9",
        "description": "Peripheral arterial occlusive disease, unspecified",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df055",
        "category": "ICD-10",
        "code": "E11.41",
        "description": "Diabetes with peripheral angiopathy",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df056",
        "category": "ICD-10",
        "code": "T82.89",
        "description": "Other unspecified sequelae of traumatic amputation",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df057",
        "category": "ICD-10",
        "code": "Z99.2",
        "description": "Long-term (current) use of peripheral vascular devices (e.g., implanted stents)",
        "applicable": false,
        "addressed_date": "-"
      }
    ]
  },
  {
    "id": 4,  // Assuming a unique ID for this record
    "date": "06/01/2023",
    "hcc_code": "21",
    "hcc_description": "Malnutrition",
    "source": "Past Claims",
    "icd": [
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df058",
        "category": "ICD-10",
        "code": "E40-E46",
        "description": "Nutritional deficiencies",
        "applicable": false,  "addressed_date": "-",
        "note": "**Specific codes within this range can be used depending on the type of malnutrition.**"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df059",
        "category": "ICD-10",
        "code": "E64.9",
        "description": "Unspecified deficiency of vitamins and minerals",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df060",
        "category": "ICD-10",
        "code": "R63.0",
        "description": "Malabsorption syndrome",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df061",
        "category": "ICD-10",
        "code": "K46.3",
        "description": "Kwashiorkor",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df062",
        "category": "ICD-10",
        "code": "K47.3",
        "description": "Marasmus",
        "applicable": false,
        "addressed_date": "-"
      }
    ]
  },
  {
    "id": 5,  // Assuming a unique ID for this record
    "date": "0/01/2023",
    "hcc_code": "85",  // HCC85 can also represent Heart Failure, so double-check for accuracy
    "hcc_description": "Hypertension with Complications",
    "source": "Past Claims",
    "icd": [
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df063",
        "category": "ICD-10",
        "code": "I11.0",
        "description": "Hypertensive heart disease with heart failure",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df064",
        "category": "ICD-10",
        "code": "I12.0",
        "description": "Hypertensive heart disease without heart failure",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df065",
        "category": "ICD-10",
        "code": "I13.0",
        "description": "Hypertensive chronic kidney disease with hypertensive nephropathy",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df066",
        "category": "ICD-10",
        "code": "I13.1",
        "description": "Hypertensive chronic kidney disease without hypertensive nephropathy",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df067",
        "category": "ICD-10",
        "code": "I15.0",
        "description": "Hypertensive retinopathy",
        "applicable": false,
        "addressed_date": "-"
      }
    ]
  },
  {
    "id": 6,  // Assuming a unique ID for this record
    "hcc_code": "56",  // F1x.2 likely translates to HCC56 (Substance Use Disorder)
    "hcc_description": "Substance Use Disorder",
    "source": "NLP",
    "icd": [
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df068",
        "category": "ICD-10",
        "code": "F11.20",
        "description": "Alcohol dependence",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df069",
        "category": "ICD-10",
        "code": "F12.21",
        "description": "Opioid dependence",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df070",
        "category": "ICD-10",
        "code": "F13.23",
        "description": "Cannabis dependence",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df071",
        "category": "ICD-10",
        "code": "F15.21",
        "description": "Cocaine dependence",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df072",
        "category": "ICD-10",
        "code": "F19.23",
        "description": "Other stimulant dependence",
        "applicable": false,
        "addressed_date": "-"
      }
    ]
  },
  {
    "id": 7,
    "hcc_code": "00",  // No specific HCC code for Housing Stability
    "hcc_description": "Housing Stability",
    "source": "NLP",
    "icd": [
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df073",
        "category": "ICD-10",
        "code": "Z59.1",
        "description": "Inadequate housing",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df074",
        "category": "ICD-10",
        "code": "Z59.3",
        "description": "Inadequate housing (lack of heating/space, unsatisfactory surroundings)",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df075",
        "category": "ICD-10",
        "code": "Z59.89",
        "description": "Other problems related to housing and economic circumstances",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df076",
        "category": "ICD-10",
        "code": "Z59.811",
        "description": "Housing instability, housed, with risk of homelessness",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df077",
        "category": "ICD-10",
        "code": "Z59.812",
        "description": "Housing instability, housed, homelessness in the past 12 months",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df078",
        "category": "ICD-10",
        "code": "Z59.819",
        "description": "Housing instability, housed, unspecified",
        "applicable": false,
        "addressed_date": "-"
      }
    ]
  },
  {
    "id": 8,  // Assuming a unique ID for this record
    "hcc_code": "00",  // No specific HCC code for Material Security
    "hcc_description": "Material Security",
    "source": "NLP",
    "icd": [
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df079",
        "category": "ICD-10",
        "code": "Z59.41",
        "description": "Food insecurity",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df080",
        "category": "ICD-10",
        "code": "Z59.48",
        "description": "Other specified lack of adequate food",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df081",
        "category": "ICD-10",
        "code": "Z59.1",
        "description": "Inadequate housing (lack of heat, restriction of space, technical home defects, unsatisfactory surroundings)",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df082",
        "category": "ICD-10",
        "code": "Z58.6",
        "description": "Inadequate drinking-water supply",
        "applicable": false,
        "addressed_date": "-"
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df083",
        "category": "ICD-10",
        "code": "Z63.6",
        "applicable": false,
        "addressed_date": "-",
        "note": "Z63.6 (Dependent relative needing care at home) might not directly relate to material security. Consider using your clinical judgement."
      },
      {
        "id": "e5fc01aa-ebbc-4040-998a-730dd03df084",
        "category": "ICD-10",
        "code": "Z59.8",
        "description": "Other problems related to housing / economic circumstances",
        "applicable": false,
        "addressed_date": "-"
      }
    ]
  }  
]
          


            