import React, { useContext } from "react";
import { CancelRounded, InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import { toPluralize } from "../../Utils/stringOprations";
import { PyramidContext } from "../../Contexts/uiContext";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import "./_PatientList.scss";
import { 
  splitFilters, 
  findFiltersByKey, 
  deepClone, 
  updateOptionsById,
  TooltipTitleDescription 
} from "../../Utils/filterPatientList";

const AppliedFilterList = ({ dropdownFilterKey, removeFilter, sstate }) => {
  const { patientSetInfo } = useContext(PatientSetContext);
  const { state, dispatch } = useContext(DropdownFilterContext);
  const { info, setInfo } = useContext(PyramidContext);
  const { user } = JSON.parse(localStorage.getItem("user"));
  const { org_guid } = user;
  const carolinaOrgGuid = "10a7319f-b662-4c79-85fd-7fc5c9cc1461"
  const activeTitle = org_guid === carolinaOrgGuid ? "": "active";

  const ShowToolTipIcon = () => {
    let showToolTip = state.identifiers_checkbox["options_checkbox"]
      .map((filter) =>
        filter.options.some((opt) =>
          state.identifiers_checkbox["selected_checkboxes"].includes(opt.name)
        )
      )
      .some((val) => val === true);
    
    let isBlinking = true;

    const stopBlinking = () => {
      isBlinking = false;
      const iconElement = document.querySelector('.applied-filter-list-info-icon');
      if (iconElement) {
        iconElement.classList.remove('applied-filter-list-info-icon-blinking');
      }
    };

    // Set a timeout to stop blinking after 10 seconds
    !sstate.left && setTimeout(stopBlinking, 10000);

    const getClass = () => {
      return isBlinking && !sstate.left ? 'applied-filter-list-info-icon-blinking' : '';
    };
  
    return showToolTip ? (
      <Tooltip title={TooltipTitleDescription} placement="top">
        <InfoOutlined className={`applied-filter-list-info-icon ${getClass()} `}/>
      </Tooltip>
    ) : null;
  };


  const handleButtonDragStart = (event, option) => {
    event.dataTransfer.setData('text/plain', JSON.stringify(option));
  };

  const handleButtonDragEnd = (event) => {
  };

  const handleChipDragOver = (event) => {
    event.preventDefault();
  };

  const handleInsideDivDrag = (event) => {
  };

  const handleInsideChipDrop = (event) => {
    event.preventDefault();
    // recieving the filter which will be merge
    const selectedFilterForMerge = JSON.parse(event.dataTransfer.getData('text/plain'));
    const draggedItemParentId = selectedFilterForMerge?.id;
    // element in which dragged element being dropped
    let currentElement = event.target;
    // parent of element in which dragged element is dropped
    let droppableContainerElement = currentElement.parentNode;
    let droppableContainerParentId = droppableContainerElement.id;
    // if droppable element will not be available
    if(!droppableContainerParentId) return;
    if( draggedItemParentId === droppableContainerParentId) return;
    let droppableElementTitle = droppableContainerElement.title;
    const{ title } = currentElement;
    // if it is dragged outside of chip then drop should not happen
    if(title === "patient-list-applied-filter-container") return
    // this code ensures that it should merge into the same category
    if(droppableElementTitle !== selectedFilterForMerge.title) return;
    let clonedStateData = deepClone(state);
    let {appliedFilterCheckboxes: { appliedFilterCheckboxesList : filtersData}} = clonedStateData;

    // this code removes the selected chip from the from its parents chip list
    let updatedFiltersList = filtersData.map((filter) => {
          let filteredItem = {...filter, options: filter.options.filter((p) => p.name !== selectedFilterForMerge.name)}
          return filteredItem.options.length > 0 ? filteredItem : null
    }).filter((item) => item !== null);

    // this code merge the selected filter into the filter list where it is dropped 
    let clonedFilterList = deepClone(updatedFiltersList)
    let finalFiltersList = updateOptionsById(clonedFilterList,droppableContainerParentId, selectedFilterForMerge);

    dispatch({
      type: "applied-checkbox-filter-update",
      payload: {
        isMergingFilters: true,
        data: finalFiltersList
      },
    });
  };

  // this code executes when we split the merge filter
  const handleInsideDivDrop = (event) => {
    // recieves filter value which will be split
    const data = JSON.parse(event.dataTransfer.getData('text/plain'));
    const filtersData = state?.appliedFilterCheckboxes?.appliedFilterCheckboxesList;
    // title of container in which element beigh dropped
    let title = event.target.title;
    // checks filter is dropped in main container
    if(title !== "patient-list-applied-filter-container") return;
    // selects the dragged filter fron list of filters
    const filtersDataClone = JSON.parse(JSON.stringify(filtersData))
    const selectedFilter = findFiltersByKey(filtersDataClone, data.name);
    // if chips contain single filter it cannot be splitted
    if(selectedFilter?.options?.length === 1) return;
    // creates a separate chip for selected filter 
    let selectedFilterForMerge = deepClone(data);
    const updatedFiltersList = splitFilters(filtersDataClone, selectedFilterForMerge);
    const updatedFiltersListCloned = deepClone(updatedFiltersList)
    dispatch({
      type: "applied-checkbox-filter-update",
      payload: {
        isMergingFilters: true,
        data: [...updatedFiltersListCloned]
      },
    });
  };

  const handleInsideDivDragOver = (event) => {
    event.preventDefault();
  };

  const handleOutlierLabel = (label, option) =>{
    const { mnemo, name} = option;
    if(mnemo === "outlier"){
      const msgData = state?.outlierFilter[name];
      return msgData["msg"]
    }
    return label;
  }

  return (
    <div 
        className="patient-list-applied-filter-container"
        title="patient-list-applied-filter-container"
        onDragEnd={handleInsideDivDrag}
        onDrop={handleInsideDivDrop}
        onDragOver={handleInsideDivDragOver}
    >
      {/* Rendering the name & direction of the key on which the table data was sorted. */}
      {dropdownFilterKey ? (
        <div className="list-container">
          <small className="list-main-heading">Sorted by</small>
          <small className="inner-list">
            <div className="inner-list-item">
              {dropdownFilterKey.name}
              {dropdownFilterKey.direction === "ascending" ? (
                <ArrowDownwardIcon
                  style={{ fontSize: "16px", verticalAlign: "sub" }}
                />
              ) : (
                <ArrowUpwardIcon
                  style={{ fontSize: "16px", verticalAlign: "sub" }}
                />
              )}
            </div>
          </small>
        </div>
      ) : null}

      {
        state?.appliedFilterCheckboxes?.appliedFilterCheckboxesList?.map((item, id) => {
            const {title: oldTitle, options, id: id2} = item;
            
            const title = oldTitle === "LOB" ? "LoB" : oldTitle;

            const handleRemoveFilter = (mnemo, abbr) => {
              removeFilter({ name: `${mnemo}_${abbr}`, selected: 0, _mnemo : mnemo});
            };

            return (
              <div 
                key={id} 
                className="list-container" 
                id={id2}
                onDrop={handleInsideChipDrop}
                onDragOver={handleChipDragOver}
                title={title}
              >
                <small className="list-main-heading" >{title}</small>
                <ul className="inner-list" id={id2}>
                  {
                    options.map((option, index) =>{
                      const {label,mnemo, selected, abbr, key} = option;
                      const isSelected =  selected === 2;
                      return <div 
                                className="inner-list-item" 
                                draggable
                                onDragStart={function(event) {
                                  handleButtonDragStart(event, { ...option, title, id: id2 });
                              }}
                                onDragEnd={handleButtonDragEnd}
                                index={index}
                                title={title}
                                id={id2}
                                key={index}
                              >
                                <small 
                                    className={isSelected ? "deselected" : ""} 
                                    title={title}
                                    id={key}
                                >
                                  {isSelected ? <s>{label}</s> : handleOutlierLabel(label, option)}
                                </small>
                                <CancelRounded className="cancel-icon" alt="close" onClick={() => handleRemoveFilter(mnemo, abbr)} />
                              </div>
                    })
                  }
                </ul>
              </div>
            );
        })
      }
    
      {/* Rendering the "From-To : Range/Individual" value from dropdown */}
      {state.selectedFilters.map((filter) => {
        return !filter.apply ? null : filter.qf ? (
          <div key={filter.key} className="list-container">
            <small className="list-main-heading">Quick-Filter</small>
            <ul className="inner-list">
              <li className="inner-list-item">
                <small>
                  {filter.title}
                  <CancelRounded
                    className="cancel-icon"
                    alt="close"
                    onClick={() =>
                      dispatch({
                        type: "reset-state",
                        payload: { id: filter.key },
                      })
                    }
                  />
                </small>
              </li>
            </ul>
          </div>
        ) : (
          <div key={filter.key} className="list-container">
            <small className="list-main-heading">{filter.title}</small>
            <ul className="inner-list">
              <li className="inner-list-item">
                <small>
                  {(filter.key === "cs" ||
                    filter.key === "drc" ||
                    filter.key === "imp" ||
                    filter.key === "qual") &&
                  filter.stratus
                    ? filter.stratus
                    : filter.to === ""
                    ? `${filter.from}`
                    : filter.from === ""
                    ? `${filter.to}`
                    : filter.key === "dob"
                    ? `${filter.from} to ${filter.to}`
                    : `${filter.from} - ${filter.to}`}
                  {filter.individual === "" ? "" : `${filter.individual}`}
                  <CancelRounded
                    className="cancel-icon"
                    alt="close"
                    onClick={() => {
                      if (
                        filter.key === "cs" ||
                        filter.key === "drc" ||
                        filter.key === "imp" ||
                        filter.key === "qual"
                      ) {
                        setInfo({
                          ...info,
                          pyramidRiskStatus: "none",
                          uititle: `All ${activeTitle} ${toPluralize(
                            patientSetInfo.patient_name
                          )}`,
                        });
                      }
                      dispatch({
                        type: "reset-state",
                        payload: { id: filter.key },
                      });
                    }}
                  />
                </small>
              </li>
            </ul>
          </div>
        );
      })}

      {/* Rendering the NLP filters */}
      {Object.keys(state.nlp_filter).map((key) => {
        const filter =  state.nlp_filter[key];

        return (state.nlp_filter[key].value !== 0 ?
          <div key={state.nlp_filter[key]} className="list-container">
            <small className="list-main-heading">Other Filter(s)</small>
            <ul className="inner-list">
              <li className="inner-list-item">
                <small className={filter.value === 2 ? "deselected" : ""}>
                {filter.value === 2 ? (<s>{state.nlp_filter[key].label}</s>) : state.nlp_filter[key].label}
                  <CancelRounded
                    className="cancel-icon"
                    alt="close"
                    onClick={() => {
                      Object.values(state.nlp_filter).forEach(item => item.disabled = false);
                      dispatch({ type: "disable-nlp-filter", payload: state.nlp_filter });
                      dispatch({
                        type: "set-nlp-filter",
                        payload: {
                          name: filter.name,
                          value: 0,
                        },
                      });
                     }
                    }
                  />
                </small>
              </li>
            </ul>
          </div> : null
        )
      })}

      {state.identifiers_checkbox["options_checkbox"].length
        ? ShowToolTipIcon()
        : null}
    </div>
  );
};

export default AppliedFilterList;
